.basic-data {
    flex: 7;
    min-width: 100%;
    width: 100%;
}
.basic-data-row {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-flow: wrap;
    min-width: calc(100%);
}
.basic-data-title {
    flex: 1;
    min-width: fit-content
}

.monthly-pnl {
    flex: 5;
    min-width: 100%;
    width: 100%;
}

.normal-card{
    flex: 6;
    min-width: 100%;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .basic-data{
        min-width: 500px;
    }
    .basic-data-row {
        min-width: 450px;
    }
    .basic-data-title {
        min-width: 300px;
    }

    .monthly-pnl{
        min-width: 400px;
    }
    .normal-card{
        min-width: 550px;
    }
}

@media only screen and (min-width: 1200px) {
    .basic-data-row {
        min-width: 400px;
        margin: 0px 8px;
    }
    .basic-data-title {
        min-width: 300px;
    }
}