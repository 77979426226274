.portal-menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.portal-item {
    flex: 0;
    max-width: 100%
}

@media only screen and (min-width: 768px) {
    .portal-menu {
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        justify-content: center;
    }
    
    .portal-item {
        flex: 0;
        min-width: 300px;
        max-height: 300px;
    }
}